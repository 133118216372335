import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Map from "../components/Map";
import CCLicense from "../components/CCLicense";
import Img from "gatsby-image"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'


const LocationPage = ({data, pageContext}) => {
  const location = data.locationsJson;
  
  const title = location.images[0].title ? location.images[0].title : `Photo from ${location.city}`;

  const image = location.images[0];

  let licenseTag;
  if (image.contributor) {
    if (image.ccLicenseName) {
      licenseTag = (
        <CCLicense
          attributionName={image.contributor.name} 
          title={title} 
          ccLicenseUrl={image.ccLicenseUrl}
          ccLicenseName={image.ccLicenseName}
          ccLicenseSlug={image.ccLicenseSlug}
        />
      )
    } else {
      licenseTag = (
        <div className="credit-legal">&copy; 2020 {image.contributor.name}. Used with permission</div>
      )
    }
  }

  let extraSource;
  if (image.sourceUrl) {
    const linkText = image.sourceName || "link";
    extraSource = (
      <span>
        via
        <a rel="noopener" target="_blank" href={image.sourceUrl}> {linkText}&rarr;</a>
      </span>
    )
  }

  let earlierLink;
  if (pageContext.earlier) {
    earlierLink = <Link 
      onClick={e => {
        trackCustomEvent({
          category: "navigation",
          action: "location-earlier",
          label: location.id,
        })
      }}
      className="earlier" to={pageContext.earlier.fields.path}>&larr; 
    </Link>
  }
  let laterLink;
  if (pageContext.later) {
    laterLink = <Link 
      onClick={e => {
        trackCustomEvent({
          category: "navigation",
          action: "location-later",
          label: location.id,
        })
      }}
    className="later" to={pageContext.later.fields.path}>&rarr;</Link>
  }

  const instagramHandle = location.images[0].instagram;
  let instagramLink;
  if (instagramHandle) {
    const instagramUrl = `https://instagram.com/${instagramHandle.trim()}`
    instagramLink = <a 
      onClick={e => {
        trackCustomEvent({
          category: "external-link",
          action: "contributor-instagram",
          label: instagramHandle,
        })
      }}
      href={instagramUrl}>(@{instagramHandle.trim()})
    </a>;;
  }

  const twitterHandle = location.images[0].twitter;
  let twitterLink;
  if (twitterHandle) {
    const twitterUrl = `https://twitter.com/${twitterHandle.trim()}`
    twitterLink = <a 
      onClick={e => {
        trackCustomEvent({
          category: "external-link",
          action: "contributor-twitter",
          label: twitterHandle,
        })
      }}
      href={twitterUrl}>(@{twitterHandle.trim()})
    </a>;;
  }

  return (
    <Layout>
      <SEO title={title} image={location.images[0].src.childImageSharp.fluid.src}/>
      <div className="location-meta">
        <div className="nav-arrows">
          {earlierLink}
          {laterLink}
        </div>
        <div className="breadcrumbs">
          <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-country", label: location.country})}} to='/cities'>{location.country}</Link> / 
          <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-state", label: location.state})}} to='/cities'> {location.state}</Link> / 
          <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-city", label: location.city})}} to={location.fields.cityFullPath}> {location.city}</Link>
        </div>
      </div>
      {location.images.map((image, i) => (
        <div key={i} className="location-image">
          <div className="photo">
            <Img fluid={image.src.childImageSharp.fluid} />
          </div>
          <div className="meta">
            {image.title && (
              <h2>{image.title}</h2>
            )}
            <dl>
              <dt>Location</dt>
              <dd>
                {location.sublocation}<br/>
                <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-city", label: location.city})}} to={location.fields.cityFullPath}> {location.city}</Link>, 
                <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-state", label: location.state})}} to='/cities'> {location.state}</Link>, 
                <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-country", label: location.country})}} to='/cities'> {location.country}</Link>
              </dd>

              <div className="mini-map">
                <Map latitude={location.lat} longitude={location.lng}/>
              </div>

              {image.keywords.length > 0 && (
                <span>
                  <dt><Link onClick={e => { trackCustomEvent({ category: "navigation", action: "all-tags"}) }} to="/tags">Tags</Link></dt>
                  <dd>{image.keywords.map((keyword, i) => (
                    <span id={i} className="count">
                      <Link onClick={e => {trackCustomEvent({category: "navigation", action: "location-tag", label: keyword.slug})}} to={`tags/${keyword.slug}`}>
                        {keyword.name}
                      </Link>
                    </span>
                  ))}
                  </dd>
                </span>
              )}

              <dt>Taken on</dt>
              <dd>{image.date}</dd>

              {image.contributor && (
                <span>
                  <dt>Photo Credit</dt>
                  <dd><Link
                        onClick={e => {
                          trackCustomEvent({
                            category: "navigation",
                            action: "contributor",
                            label: image.contributor.slug
                          })
                        }}
                   to={`contributors/${image.contributor.slug}`}>{image.contributor.name}</Link> {instagramLink} {twitterLink}
                  {extraSource}
                  {licenseTag}
                  </dd>
                </span>
              )}
            </dl>
          </div>
        </div>
      ))}
    </Layout>
  )
}
  
export default LocationPage

export const pageQuery = graphql`
  query LocationQuery($slug: String) {
    locationsJson(slug: {eq: $slug}) {
      fields {
        cityFullPath
      }
      sublocation
      city
      state
      country
      images {
        date(formatString: "LL")
        contributor {
          slug
          name
        }
        instagram
        twitter
        caption
        keywords {
          slug
          name
        }
        ccLicenseUrl
        ccLicenseName
        ccLicenseSlug
        sourceUrl
        sourceName
        title
        src {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      name
      slug
      lat
      lng
    }
  }
`
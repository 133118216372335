import React from 'react';
import MapGL, { Marker } from '@urbica/react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useMapboxConfig } from "../hooks/mapbox-token";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default function Map(props) {
  const style = {
    width: '14px',
    height: '14px',
    display: 'block',
    color: '#fff',
    cursor: 'pointer',
    background: '#B4192E',
    borderRadius: '100%'
  };

  return (
    <MapGL
      style={{ width: '100%', height: '330px' }}
      mapStyle="mapbox://styles/mapbox/light-v9"
      accessToken={useMapboxConfig().mapboxToken}
      latitude={props.latitude}
      longitude={props.longitude}
      zoom={15}
    >
        <Marker longitude={props.longitude} latitude={props.latitude}>
          <a onClick={e => {trackCustomEvent({category: "external-link", action: "location-map"})}} style={style} rel="noopener noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${props.latitude},${props.longitude}`}></a>
        </Marker>
    </MapGL>
  )
}

